import axios from 'axios'

export const state = {
  cycles: [],
  report: [],
  reportUpdateCounter: 0
}

export const mutations = {
  SET_CYCLES (state, data) {
    state.cycles = data.cycles
  },

  INCREMENT_REPORT_UPDATE (state, data) {
    state.reportUpdateCounter = state.reportUpdateCounter + 1
  },

  formatPrice (value) {
    const val = (value / 1).toFixed(0).replace(',', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  SET_REPORT (state, data) {
    for (let index = 0; index < data.length; index++) {
      const reports = data[index]
      for (let j = 0; j < reports.reportTypes.length; j++) {
        const report = reports.reportTypes[j]
        for (let k = 0; k < report.content.length; k++) {
          const reportContent = report.content[k]
          reportContent.reportValue = (reportContent.reportValue / 1)
          reportContent.reportCount = (reportContent.reportCount / 1)
          state.report = data
        }
      }
    }
  }
}

export const actions = {
  getReport ({ commit }, data) {
    return axios.post('api/Report/run', data).then(response => {
      if (response.data.success) {
        commit('SET_REPORT', response.data.data)
        commit('INCREMENT_REPORT_UPDATE')
        return response
      } else {
        return response
      }
    })
  }
}

export const getters = {
  cyclesGetter: state => state.cycles,
  reportGetter: state => state.report
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
