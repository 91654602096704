<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <span class="pb-4">{{ report.name }}</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="جستجو"
            filled
            dense
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headerTable"
          :items="report.content"
          :items-per-page="5"
          :search="search"
          mobile-breakpoint="0"
        >
          <template v-if="report.content.length  " slot="body.append">
            <tr>
              <th v-for="(item,ind) in rowTotal" :key="ind">{{item.text}}</th>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Report',

  // Computed
  computed: {
    ...mapGetters('report', ['reportGetter'])
  },

  // Data
  data () {
    return {
      report: [],
      headerTable: [],
      reportId: null,
      search: '',
      rowTotal: []
    }
  },

  // Before Route Update
  beforeRouteUpdate (to, from, next) {
    this.reportId = to.params.id
    this.dataCustomizer()
    next()
  },

  // Created
  created () {
    this.reportId = this.reportId || this.$route.params.id
    this.dataCustomizer()
  },
  watch: {
    '$store.state.report.reportUpdateCounter': {
      immediately: true,
      handler (val) {
        this.dataCustomizer()
      }
    }
  },
  // Methods
  methods: {
    dataCustomizer () {
      const thousandSeparator = (val) => {
        return Number(val).toFixed(0)
          .replace(',', ',')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      for (let i = 0; i < this.reportGetter.length; i++) {
        const reports = this.reportGetter[i]
        for (let j = 0; j < reports.reportTypes.length; j++) {
          const report = reports.reportTypes[j]
          if (report.id === Number(this.reportId)) {
            report.content = report.content.map(x => {
              return {
                ...x,
                reportValueStr: thousandSeparator(x.reportValue),
                reportCountStr: thousandSeparator(x.reportCount)
              }
            })
            this.report = report
            this.headerTable = []
            this.headerTable.push(
              {
                text: this.report.reportDescCaption,
                value: 'reportDesc'
              },
              {
                text: this.report.reportCountCaption,
                value: 'reportCountStr',
                aggColName: 'reportCount'
              },
              {
                text: this.report.reportValueCaption,
                value: 'reportValueStr',
                aggColName: 'reportValue'
              }
            )
            break
          }
        }
      }
      const sum = (arr, key) => arr.reduce((a, c) => { a += c[key]; return a }, 0)

      // console.log('this.headerTable', this.headerTable)
      // console.log('this.report', this.report)
      this.rowTotal = this.headerTable.map(x => {
        if (x.value === 'reportDesc') return { text: null, value: null }
        return {
          text: ` جمع : ${thousandSeparator(sum(this.report.content, x.aggColName))}`
          // value: sum(this.report.content, x.aggColName)
        }
      })
    }
  }
}
</script>
