import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fa from 'vuetify/es5/locale/fa'
import colors from 'vuetify/lib/util/colors'

import Fonts from '../../public/static/fonts/fonts.css'
import mainCss from '../main.css'
Vue.use(Fonts)
Vue.use(mainCss)

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: colors.lightBlue.darken3,
        secondary: colors.grey.lighten1
      },

      dark: {
        primary: colors.lightBlue.accent3
      }
    },

    options: {
      customProperties: true
    }
  },

  lang: {
    locales: { fa },
    current: 'fa'
  }
})
