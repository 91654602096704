<template>
  <v-dialog class="white" :value="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="dialogOpener"
        @click="dialogHandle"
        v-bind="attrs"
        v-on="on"
      ></slot>
    </template>
    <v-card>
      <v-btn
        class="mb-3"
        fab
        elevation="0"
        small
        icon
        tile
        @click="dialogHandle"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text>
        <v-container class="pa-0">
          <ValidationObserver ref="observer">
            <form>
              <v-row align="center" justify="center">
                <v-col cols="12" class="pa-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="انتخاب دوره مالی"
                    rules="required"
                  >
                    <v-select
                      v-model="cycleName"
                      :items="cycleNames"
                      label="انتخاب دوره مالی"
                      :error-messages="errors"
                      persistent-hint
                      return-object
                      single-line
                      outlined
                      filled
                      item-text="cycl_Name"
                      @input="getCycleCode"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row justify="center" class="pb-6">
                <v-col cols="10" class="pa-0 ">
                  <VuePersianDatetimePicker
                    class="d-block"
                    v-model="startDate"
                    label="شروع"
                  ></VuePersianDatetimePicker>
                </v-col>
                <v-col cols="2" class="pa-0 ma-0">
                <v-btn @click="()=>startDate=null" class="" style="height: 40px"><v-icon icon="mdi-cancel">mdi-close</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row justify="center" class="pb-6">
                <v-col cols="10" class="pa-0">
                  <VuePersianDatetimePicker
                    v-model="endDate"
                    label="پایان"
                    :disabled="!startDate"
                    :min="startDate"
                  ></VuePersianDatetimePicker>
                </v-col>
                <v-col cols="2" class="pa-0">
                <v-btn @click="()=>endDate=null" style="height: 40px"><v-icon icon="mdi-cancel">mdi-close</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row justify="center" class="pb-6">
                <v-col cols="12" class="pa-0">
                  <v-btn
                    elevation="0"
                    large
                    color="primary"
                    block
                    @click="getReport"
                    :loading="isLoading"
                  >
                    گرفتن اطلاعات
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import { mapGetters } from 'vuex'

export default {
  name: 'GetReportForm',

  // Components
  components: {
    VuePersianDatetimePicker
  },

  // Props
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  // Data
  data () {
    return {
      startDate: '',
      endDate: '',
      cycleName: '',
      cycleCode: '',
      cycleNames: []
    }
  },

  // Computed
  computed: {
    ...mapGetters('report', ['cyclesGetter'])
  },

  // Mounted
  mounted () {
    this.cycleNames = this.cyclesGetter
    if (this.cycleNames.length) {
      this.cycleName = this.cycleNames[this.cycleNames.length - 1]
      this.cycleCode = this.cycleNames[this.cycleNames.length - 1].cycl_Code
    }
  },

  // Methods
  methods: {
    dialogHandle () {
      this.$emit('dialogHandle')
    },

    getReport () {
      this.$emit('getReport', {
        fromDate: this.startDate,
        toDate: this.endDate,
        cycleCode: this.cycleCode
      })
    },

    getCycleCode (obj) {
      this.cycleCode = obj?.cycl_Code
    }
  }
}
</script>
