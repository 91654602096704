import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import report from './modules/report'

import VuexPersistence from 'vuex-persist'
// import localForage from 'localforage'
const vuexLocal = new VuexPersistence({
  // storage: localForage,
  // asyncStorage: true,
  storage: window.localStorage,
  modules: ['account', 'report']
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    report
  },
  plugins: [vuexLocal.plugin]
})
