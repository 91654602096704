import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Dashboard from '../views/Dashboard'
import Message from '../views/Message'
import Report from '../views/report/Report'
import PrimaryLayout from '../views/layouts/Primary'

import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  // Home
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  // Primary Layout
  {
    path: '/',
    component: PrimaryLayout,
    meta: { requiresAuth: true },
    children: [
      // Dashboard
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      // Message
      {
        path: '/message',
        name: 'Message',
        component: Message
      },
      // Report
      {
        path: 'report/:id',
        name: 'Report',
        component: Report
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters['account/isAuthenticatedGetter']
    if (!isAuthenticated) {
      next({
        name: 'Home',
        redirect: to.fullPath
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
